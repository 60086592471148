import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react'
import { EventStreamTeaserProps, MetaDataProps } from '@sport1/types/web'
import dayjs from 'dayjs'
import MidTeaser from '@sport1/news-styleguide/MidTeaser'
import { getStreamTeaserLabels } from '../utils'
import { useTracking } from '@/utils/tracking/TrackingProvider'
import useInViewport from '@/hooks/useInViewport'
import { mapAndHydrateTrackingData } from '@/components/UpcomingEventStreams/Teaser/tracking'
import { getPublishedDateWithoutYear } from '@/helpers/dateHelper'

const EventStreamTeaser: FunctionComponent<
    EventStreamTeaserProps & { meta?: MetaDataProps; link: string }
> = ({
    meta,
    tag,
    startTime,
    imageUrl,
    id,
    link,
    episodeTitle,
    isHighlight,
    endTime,
    paymentInformation,
}) => {
    const labelType = dayjs().isAfter(dayjs(startTime)) ? 'live' : undefined
    const { trackTeaserImpression, trackTeaserClick } = useTracking()
    const ref = useRef<HTMLDivElement>(null)
    const isVisible = useInViewport(ref)

    const trackingData = useMemo(
        () => mapAndHydrateTrackingData({ tracking: meta?.tracking, labelType, link }),
        [meta?.tracking, labelType, link]
    )

    useEffect(() => {
        if (isVisible) {
            trackTeaserImpression(trackingData)
        }
    }, [isVisible, trackingData, trackTeaserImpression])

    const onPress = useCallback(() => {
        trackTeaserClick(trackingData)
    }, [trackTeaserClick, trackingData])

    return (
        <MidTeaser
            type="STREAM"
            innerRef={ref}
            headlineText={episodeTitle}
            mainImageUrl={imageUrl}
            mainImageAlt={episodeTitle}
            tag={{
                name: tag?.title || '',
                imageUrl: tag?.iconUrl,
                imageAlt: tag?.title,
            }}
            labels={getStreamTeaserLabels({
                isHighlight,
                startTime,
                endTime,
                paymentInformation,
            })}
            published={getPublishedDateWithoutYear(startTime)}
            onPress={onPress}
            testID={`event-teaser-${id}`}
            href={link}
            verticalExpand
            webTarget="_blank"
        />
    )
}

export default EventStreamTeaser
